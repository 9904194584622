<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height class="px-0 py-0">
        <v-layout row wrap fill-height>
          <v-flex sm12 md7 lg8 xl8 class="hidden-sm-and-down primary">
            <v-layout row fill-height align-center justify-center>
              <v-flex xs11>
                <v-layout column justify-center align-center>
                  <v-flex xs12>
                    <img src="/login_img.png" alt="idenfit" width="640" />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md5 lg4 xl4>
            <v-layout fill-height justify-center align-center>
              <v-flex xs10 sm8 md10>
                <transition name="slide-x-transition" mode="out-in">
                  <router-view />
                </transition>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <notification-bar />
  </v-app>
</template>

<script>
  export default {
    name: "auth-layout"
  };
</script>

<style scoped>
  .brand {
    font-family: "Paytone One", sans-serif;
    font-size: 100px;
    display: block;
  }
</style>
